import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Testimonial from "../sections/index/indexreviews.js";
import Contentmasseter from "../sections/masseter/massetercontent.js";
import RecoveryMasseter from "../sections/masseter/maseterrecovery.js";
import MasseterFaq from "../sections/masseter/masseterfaq.js";
import Head from "../components/head";
import HeroComponent from "../components/ImageHero.js";
import Rte from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js'; 
import imgVideo from "../assets/image/jpeg/masseter.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const Masseter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
    <Head title="Masseter Treatment UK" image="/images/dermamina-home.jpg"  description="Experts in Masseter treatments London" keywords="Masseter, Masseter London, Masseter Therapy, Masseter Treatment, masseter face, masseter rejuvination"/>
<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">Masseter </span> <span style="color: #00aec7;">Treatment</span>'
    secondText="Relax, ease & soften your muscles"
    videoAlt="Masseter"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />
  <Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Masseter"
  resultstext="Up to 6 months"
  resultsalt="Masseter results"
  costdata="£340"
  costalt="Masseter Cost"
  timedata="10 Minutes"
  timealt="Masseter Duration Time"
  workdata="Immediately"
  workalt="Masseter downtime work"
  paindata="None / Mild"
  painalt="Masseter Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Testimonial />
<Contentmasseter setIsModalOpen={setIsModalOpen}/>
<MasseterFaq
  setIsModalOpen={setIsModalOpen}
/>
<Clinic /> 
<Saveface />
<Rte />
</PageWrapper>

    </>
  );
};
export default Masseter;
