

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

export default function Masseterfaq({setIsModalOpen}) {
    return (
        <>
        <Separator />
       
        <Section py={4} id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5 ">
          <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
            <Row className="justify-content-center pb-4">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about <span>Masseter</span></Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >

                <Accordion allowZeroExpanded classname="">

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        1. What is Masseter (The Side Set Jaw) Treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Masseter Treatment is a non-surgical cosmetic procedure aimed at addressing the appearance of
                        the masseter muscle, which is located on either side of the face near the corner of the jawline.
                        This treatment helps to soften and define facial features by targeting the masseter muscle,
                        which can become enlarged due to habits such as teeth grinding and clenching.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        2. How does this treatment differ from surgical jawline procedures?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Unlike surgical jawline procedures, Masseter Treatment is non-invasive and does not require
                        incisions or general anesthesia. It involves the use of injectable neurotoxins or other agents
                        to relax and slim down the masseter muscle, resulting in a more contoured and feminine
                        appearance without the need for surgery.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        3. What is non-surgical rhinoplasty?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Non-surgical rhinoplasty, also known as a non-surgical nose job, is a cosmetic procedure that
                        involves the use of injectable fillers to reshape and enhance the appearance of the nose without
                        surgery. It can be an effective solution for addressing minor imperfections or asymmetries in
                        the nose, providing immediate results with minimal downtime.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        4. How long do the results of Masseter Treatment last?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The results of Masseter Treatment typically last up to 6 months. However, individual results may
                        vary depending on factors such as metabolism, muscle activity, and the specific type of
                        treatment used. Periodic maintenance treatments may be recommended to maintain optimal results
                        over time.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        5. What is the cost of Masseter Treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The cost of Masseter Treatment is £340. This price may vary depending on factors such as the
                        amount of product used, the expertise of the practitioner, and any additional treatments or
                        services included in the package. A personalized consultation can provide a more accurate cost
                        estimate based on individual needs.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        6. How long does the procedure take?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Masseter Treatment procedure typically takes around 10 minutes to complete. This quick and
                        efficient process allows patients to undergo treatment during their lunch break or between
                        errands, with minimal disruption to their daily schedule.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        7. Is there any downtime after the treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, there is minimal to no downtime after Masseter Treatment. Patients can resume their normal
                        daily activities immediately after the procedure, including work, exercise, and socializing.
                        However, it is important to follow post-treatment care instructions provided by the practitioner
                        to ensure optimal results and minimize any potential side effects.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        8. Does Masseter Treatment involve any pain?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Most patients experience minimal discomfort during Masseter Treatment, with some reporting no
                        pain at all. The neurotoxin or other agents used typically contain a local anesthetic to enhance
                        comfort during the injection process. Any discomfort experienced is usually mild and temporary,
                        subsiding shortly after the procedure.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        9. How soon can I expect to see results?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Results from Masseter Treatment may start to become noticeable within a few days to a week after
                        the procedure. As the masseter muscle gradually relaxes and reduces in size, patients can
                        observe a slimmer and more contoured appearance to the jawline and lower face.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        10. Are the treatments customized to individual needs?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, all Masseter Treatments are tailored to the individual needs and preferences of each
                        patient. Prior to the procedure, a comprehensive consultation is conducted to understand the
                        patient's goals, assess their facial anatomy, and determine the most suitable treatment
                        approach. This personalized approach ensures that each patient receives optimal results that
                        enhance their natural features and achieve their desired aesthetic outcomes.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>


              </ Col>


            </Row>


            <br />
            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
            >
              <Bookbutton>
                Book Appointment
              </Bookbutton>
            </div>


          </Container>
        </Section>

          <SeparatorEnd />
        </>


    );
}












    
